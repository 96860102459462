import React from 'react'
import { connect } from "react-redux";

import { TitleSmallContainer, TitleSmallText, TitleSmallHr } from "./titleSmall.styles";

const TitleSmall = ({text, config: { colors }}) => (
  <TitleSmallContainer>
    <TitleSmallText>{text}</TitleSmallText>
    <TitleSmallHr color={colors.primary} />
  </TitleSmallContainer>
)

const mapStateToProps = ({header: { config }}, otherProps) => ({config, otherProps})

export default connect(mapStateToProps)(TitleSmall)
