import React from 'react'

import { SubheaderContainer } from "./subheader.styles";

const SubHeader = ({text}) => (
  <SubheaderContainer>
    {text}
  </SubheaderContainer>
)

export default SubHeader
