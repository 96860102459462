import { css } from "styled-components";

export const subheader = css`
  text-align: center;
  font-family: 'Lora', serif;
  font-weight: 700;
  line-height: 1.1;
  font-size: 28px;
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
`

export const hr = css`
  height: 20px;
  margin: 0;
`
