import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import IssueItemGrid from '../../components/grid/issueItemGrid/issueItemGrid.component'
import HtmlContent from '../../components/common/htmlContent/htmlContent.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import CoverImage from '../../components/common/coverImage/coverImage.component'
import ButtonPrimary from '../../components/common/buttonPrimary/buttonPrimary.component'
import TitleSmall from '../../components/common/titleSmall/titleSmall.component'
import TitleComponent from '../../components/common/title/title.component'

import { setSubPageContent, setPostList, toggleLoader } from '../../redux/pages/pages.actions'

class PastIssues extends Component {

  componentDidMount() {
    const { setSubPageContent, setPostList, toggleLoader, locale } = this.props
    toggleLoader()
    setSubPageContent(`past-issues-${locale}`)
    setPostList(locale)
  }

  render() {
    const { page : { title }, posts, config, locale } = this.props
    const mainContent = posts.map(({title, excerpt, slug, img : {url}}, i) => (
        <IssueItemGrid
          key={i}
          img={<CoverImage url={url} slug={slug} />}
          title={<TitleSmall text={title} />}
          text={<HtmlContent html={excerpt} />}
          button={<ButtonPrimary url={`/issue-details/${slug ? slug.slice(0, -3) : ''}`} front='Czytaj dalej...' back='Szczegóły...' />}
        />
      )
    )
    const header = (<MainHeader text={title} />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | ${title}`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ pages: { page, posts }, language: { locale }, header: { config }}) => ({ page, posts, locale, config })

export default connect(mapStateToProps, { setSubPageContent, setPostList, toggleLoader })(PastIssues)
