import React from 'react'

import { BrandingContainer, LinkContainer, BrandingTextContainer, BrandingWordContainer } from "./branding.styles";

const Branding = ({url, text}) => (
  <BrandingContainer>
    <LinkContainer to={url}>
      <BrandingTextContainer>
        {text.split(' ').map((word, i) => (<BrandingWordContainer key={i}>{word}&nbsp;</BrandingWordContainer>))}
      </BrandingTextContainer>
    </LinkContainer>
  </BrandingContainer>
)

export default Branding
