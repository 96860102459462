import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import HtmlContent from '../../components/common/htmlContent/htmlContent.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import TitleComponent from '../../components/common/title/title.component'

import { setSubPageContent, toggleLoader } from '../../redux/pages/pages.actions'

class Ceeol extends Component {

  componentDidMount() {
    const { setSubPageContent, toggleLoader, locale } = this.props
    toggleLoader()
    setSubPageContent(`ceeol-${locale}`)
  }

  render() {
    const { page : { title, content }, config, locale } = this.props
    const mainContent = (<HtmlContent html={content} />)
    const header = (<MainHeader text={title} />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | ${title}`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ pages: { page }, language: { locale }, header: { config }}) => ({ page, locale, config })

export default connect(mapStateToProps, { setSubPageContent, toggleLoader })(Ceeol)
