import styled from 'styled-components';

export const SlideParagrapfArrow = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  color: #fafafa;
  transition: all .5s;
  @media screen and (max-width: 760px) {
    display: none;
  }
`

export const SlideParagrapfTexts = styled.div`
  max-width: 70%;
  margin: 5px;
  transform: translateX(150%);
  transition: all .5s;
  color: #fafafa;
  @media screen and (max-width: 760px) {
    transform: none;
    max-width: 100%;
  }
`

export const SlideParagrapfContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  transition: all .5s;
  &:hover {
    ${SlideParagrapfArrow} {
      transform: translateX(-66.5vw) rotate(180deg);
    }
    ${SlideParagrapfTexts} {
      transform: translateX(0%);
      &:before {
        content: '';
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background: rgba(0, 0, 0, .1);
        filter: blur(5px);
      }
    }
  }
`
