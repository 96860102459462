import { createStore, applyMiddleware } from "redux"
import { loadState, saveState } from "./utils";
import logger from "redux-logger";
import thunk from 'redux-thunk'

import rootReducer from './root-reducer'

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, applyMiddleware(...middlewares))

store.subscribe(() => {
  saveState({
    language: store.getState().language
  });
});

export default store
