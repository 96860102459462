import styled from 'styled-components';

export const LandingGridContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 123px);
  overflow: hidden;
  @media screen and (max-width: 760px) {
    height: auto;
  }
`

export const LandingGridRowTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 25%;
  width: 100%;
  background: #fafafa;
`

export const LandingGridRowSub = styled.div`
  height: 15%;
  width: 100%;
  display: flex;
  color: #fafafa;
  @media screen and (max-width: 760px) {
    flex-wrap: wrap;
  }
`

export const LandingGridRowMain = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  position: relative;
  background: url(${props => props.img});
  background-position: -74% top;
  @media screen and (max-width: 760px) {
    height: 100%;
  }
`

export const LandingGridRowSubLeft = styled.div`
  width: 25%;
  background: ${props => props.color};
  @media screen and (max-width: 760px) {
    width: 100%;
    height: 100%;
  }
`

export const LandingGridRowSubRight = styled.div`
  width: 75%;
  background: ${props => props.color};
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  @media screen and (max-width: 760px) {
    width: 100%;
  }
`

export const LandingGridRowMainLeft = styled.div`
  width: 25%;
  background: transparent;
  z-index: 10;
  @media screen and (max-width: 760px) {
    display: none;
  }
`

export const LandingGridRowMainRight = styled.div`
  width: 75%;
  background: ${props => props.color};
  @media screen and (max-width: 760px) {
    width: 100%;
  }
`
