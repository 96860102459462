import React from 'react'
import { connect } from "react-redux";

import { NavbarContainer, NavbarBrandingSlot, NavbarBrandingItem, NavbarLinksSlot, NavbarLinks, NavbarNavItem, NavbarMobileIcons, Hamburger, Close } from "./navbar.styles";

import Branding from '../branding/branding.component'
import LanguagePicker from '../languagePicker/languagePicker.component'

import { toggleNavbar } from "../../../redux/navbar/navbar.actions";


const Navbar = ({header: {config, hompageUrl, pages}, toggleNavbar, navbar: {isOpen}, language: {locale}}) => (
  <NavbarContainer>
    <NavbarBrandingSlot color={config.colors.primary}>
      <NavbarBrandingItem>
        <Branding url={hompageUrl} text={config[`title${locale}`]} />
      </NavbarBrandingItem>
    </NavbarBrandingSlot>
    <NavbarLinksSlot isOpen={isOpen} onClick={toggleNavbar}>
      <NavbarLinks>
        {pages.map((page, i) => (<NavbarNavItem key={i} url={page.url} name={page.name}/>))}
        <LanguagePicker />
      </NavbarLinks>
    </NavbarLinksSlot>
    <NavbarMobileIcons onClick={toggleNavbar}>
      {isOpen ? <Close className='navbar__closeicon' /> : <Hamburger className='navbar__hamburgericon' />}
    </NavbarMobileIcons>
  </NavbarContainer>
)

const mapStateToProps = ({header, navbar, language}) => ({header, navbar, language})

export default connect(mapStateToProps, {toggleNavbar})(Navbar)
