import React from 'react'
import { connect } from "react-redux";

import { LoaderContainer, LoaderThrobber } from "./loader.styles";

const Loader = ({ colors: { primary } }) => (
  <LoaderContainer>
      <LoaderThrobber color={primary} />
  </LoaderContainer>
)

const mapStateToProps = ({header: { config }}) => config

export default connect(mapStateToProps)(Loader)
