import { LanguageActionTypes } from "./language.types"

const INITIAL_STATE = {
  locale: 'pl'
}

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case LanguageActionTypes.SET_LOCALE:
        return {
          ...state,
          locale: action.payload
        }
    default:
      return state
  }
}

export default languageReducer
