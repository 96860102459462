import { PagesActionTypes } from "./pages.types"

const INITIAL_STATE = {
  showLoader: true,
  homepage: {
    content: []
  },
  page: {
    title: '',
    content: ''
  },
  posts: [{
    img: [],
    title: '',
    excerpt: ''
  }],
  lastPost: {
    img: '',
    title: '',
    excerpt: '',
    slug: ''
  },
  issueDetails: {
    img: '',
    title: '',
    content: '',
    slug: ''
  }
}

const headerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PagesActionTypes.SET_HOMEPAGE_CONTENT:
      return {
        ...state,
        homepage: action.payload
      }
    case PagesActionTypes.SET_SUBPAGE_CONTENT:
      return {
        ...state,
        page: action.payload
      }
    case PagesActionTypes.SET_POSTS_LIST:
      return {
        ...state,
        posts: action.payload
      }
    case PagesActionTypes.TOGGLE_LOADER:
      return {
        ...state,
        showLoader: action.payload
      }
    case PagesActionTypes.SET_LAST_ISSUE:
      return {
        ...state,
        lastPost: action.payload
      }
    case PagesActionTypes.SET_ISSUE_DETAILS:
      return {
        ...state,
        issueDetails: action.payload
      }
    default:
      return state
  }
}

export default headerReducer
