import styled from 'styled-components';
import { hr } from "../../../styles/common.styles";

export const SubPageGridContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 80px auto;
  margin-top: 20px;
  min-height: calc(100vh - 230px);
  @media screen and (max-width: 1024px) {
    margin: 20px 20px 0;
    width: auto;
  }
  ul>li:after {
    color: ${props => props.color};
  }
`

export const SubPageGridHr = styled.div`
  ${hr}
  background-color: ${props => props.color};
`

export const SubPageGridHeader = styled.div``

export const SubPageGridContent = styled.div``
