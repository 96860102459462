import React, { Component } from 'react'
import { connect } from "react-redux";

import LandingGrid from '../../components/grid/landingGrid/landingGrid.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import SubHeader from '../../components/common/subHeader/subHeader.component'
import SlideParagraph from '../../components/common/slideParagraph/slideParagraph.component';
import TitleComponent from '../../components/common/title/title.component';

import { setHomepageContent, toggleLoader } from '../../redux/pages/pages.actions'

class HomePage extends Component {

  componentDidMount() {
    const { setHomepageContent, toggleLoader, locale } = this.props
    toggleLoader(false)
    setHomepageContent(locale)
  }

  render() {
    const {header: {config}, homepage: {content}, locale} = this.props
    const mainHeader = (<MainHeader text={config[`title${locale}`]} />)
    const subHeader = (<SubHeader text={config[`subtitle${locale}`]} />)
    const rightParagraph = (<SlideParagraph content={content} />)
    return (
      <div className='homepage'>
        <TitleComponent title={`${config[`title${locale}`]} | ${locale === 'pl' ? 'Start' : 'Home'}`} />
        <LandingGrid Title={mainHeader} SlotB={subHeader} SlotD={rightParagraph} config={config}/>
      </div>
    )
  }
}

const mapStateToProps = ({header, pages: { homepage }, language: { locale }}) => ({header, homepage, locale})

export default connect(mapStateToProps, { setHomepageContent, toggleLoader })(HomePage)
