import styled from 'styled-components';
import { subheader, hr } from "../../../styles/common.styles";

export const TitleSmallContainer = styled.div``

export const TitleSmallText = styled.h3`
  ${subheader}
  margin: 0;
  text-align: right;
  @media screen and (max-width: 760px) {
    margin-right: 10px;
  }
`
export const TitleSmallHr = styled.div`
  ${hr}
  background-color: ${props => props.color}
`
