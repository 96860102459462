import { PagesActionTypes } from "./pages.types"

import { getParagraphsText } from "./pages.utils";

export const setHomepageContent =  locale => dispatch => {
  fetch(`/api/index.php/wp-json/wp/v2/pages?slug=about-${locale}`, { headers: { "accepts":"application/json" }})
    .then(res => res.json())
    .then(page => {
      dispatch({
        type: PagesActionTypes.SET_HOMEPAGE_CONTENT,
        payload: {content: getParagraphsText(page[0].content.rendered)}
      })}
    )
    .then(() => dispatch(toggleLoader(true)))
}

export const setSubPageContent = subpageSlug => dispatch => {
  fetch(`/api/index.php/wp-json/wp/v2/pages?slug=${subpageSlug}`, { headers: { "accepts":"application/json" }})
    .then(res => res.json())
    .then(page => {
      dispatch({
        type: PagesActionTypes.SET_SUBPAGE_CONTENT,
        payload: {
          title: page[0].title.rendered,
          content: page[0].content.rendered
        }
      })}
    )
    .then(() => dispatch(toggleLoader(true)))
}

export const setPostList = locale => dispatch => {
  fetch(`/api/index.php/wp-json/wp/v2/posts?_embed&per_page=100`, { headers: { "accepts":"application/json" }})
    .then(res => res.json())
    .then(posts => {
      dispatch({
        type: PagesActionTypes.SET_POSTS_LIST,
        payload:
          posts
            .filter((item => item.slug.slice(item.slug.length -2) === locale ? item : null))
            .map(post => ({
              img: post._embedded['wp:featuredmedia'] ? {url: post._embedded['wp:featuredmedia'][0].source_url, slug: post._embedded['wp:featuredmedia'][0].slug} : {},
              title: post.title.rendered,
              excerpt: post.excerpt.rendered,
              slug: post.slug
        }))
      })}
    )
    .then(() => dispatch(toggleLoader(true)))
}

export const setLastIssue = locale => dispatch => {
  fetch(`/api/index.php/wp-json/wp/v2/posts?_embed&per_page=2`, { headers: { "accepts":"application/json" }})
    .then(res => res.json())
    .then(posts => posts.filter(post => post.slug.slice(post.slug.length -2) === locale))
    .then(post => {
      post = post[0] ? post[0] : {}
      dispatch({
        type: PagesActionTypes.SET_LAST_ISSUE,
        payload: {
          img: post._embedded['wp:featuredmedia'] ? {url: post._embedded['wp:featuredmedia'][0].source_url, slug: post._embedded['wp:featuredmedia'][0].slug} : {},
          title: post.title.rendered,
          content: post.content.rendered,
          slug: post.slug
        }
      })}
    )
    .then(() => dispatch(toggleLoader(true)))
}

export const setIssueDetails = slug => dispatch => {
  fetch(`/api/index.php/wp-json/wp/v2/posts?_embed&slug=${slug}`, { headers: { "accepts":"application/json" }})
    .then(res => res.json())
    .then(post => {
      post = post[0] ? post[0] : {}
      dispatch({
        type: PagesActionTypes.SET_ISSUE_DETAILS,
        payload: {
          img: post._embedded['wp:featuredmedia'] ? {url: post._embedded['wp:featuredmedia'][0].source_url, slug: post._embedded['wp:featuredmedia'][0].slug} : {},
          title: post.title.rendered,
          content: post.content.rendered,
          slug: post.slug
        }
      })}
    )
    .then(() => dispatch(toggleLoader(true)))
}

export const toggleLoader = loaderState => dispatch => dispatch({
  type: PagesActionTypes.TOGGLE_LOADER,
  payload: !loaderState
})
