import styled from 'styled-components';

export const RightparagraphContainer = styled.p`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    margin: 20px;
  }
  text-align: right;
  margin-right: 10px;
  &:last-child {
    margin-bottom: 5px;
  }
`
