export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = state => localStorage.setItem('state', JSON.stringify(state))

export const comparePagesOrder = (a, b) => {
  const pageA = a.menu_order;
  const pageB = b.menu_order;

  let comparison = 0;
  if (pageA > pageB) {
    comparison = 1;
  } else if (pageA < pageB) {
    comparison = -1;
  }
  return comparison;
}
