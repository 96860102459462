import { NavbarActionTypes } from "./navbar.types";

const INITIAL_STATE = {
  isOpen: false
}

const navbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavbarActionTypes.TOGLE_NAVBAR:
      return {
        isOpen: !state.isOpen
      }
    default:
      return state
  }
}

export default navbarReducer
