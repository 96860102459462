import { combineReducers } from "redux"
import headerReducer from "./header/header.reducer";
import pagesReducer from './pages/pages.reducer'
import languageReducer from './language/language.reducer'
import navbarReducer from './navbar/navbar.reducer';
export default combineReducers({
  header: headerReducer,
  pages: pagesReducer,
  language: languageReducer,
  navbar: navbarReducer
})
