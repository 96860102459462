import styled from 'styled-components';

export const MainHeaderContainer = styled.header`
  font-family: 'Lora', serif;
  line-height: 1.1;
  font-size: 42px;
  text-align: right;
  height: 100%;
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    font-size: 36px;
  }
`

export const MainHeaderWord = styled.span`
  display: inline-block;
  font-size: 100%;
  &:first-letter{
    font-size: 125%;
  }
  &:last-child {
    display: block;
  }
`
