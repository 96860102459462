import styled from 'styled-components'
import { subheader } from "../../../styles/common.styles";
import { NavLink } from "react-router-dom"

export const NavItemText = styled.h5`
  ${subheader}
  color: inherit !important;
  margin: 0;
  padding: 0 20px;
  font-size: 14px !important;
  text-align: left;
  text-transform: uppercase;
  font-weight: 400;
  @media screen and (max-width: 760px) {
    white-space: nowrap;
  }
`

export const NavItemContainer = styled.div`
  margin: 10px 0;
  height: 100%;
  max-width: 12%;
  color: inherit;
  &:hover {
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, .1);
    background: #fafafa;
    ${NavItemText} {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 760px) {
    height: 30px;
  }
`

export const NavItemLinkWrapper = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: inherit !important;
  &.is-active {
    background: #ddd;
      @media screen and (max-width: 1024px) {
        background: transparent;
      }
  }
`
