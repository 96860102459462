import React from 'react'

import { IssueDetailsContainer, IssueDetailsItem } from "./issueDetails.styles";

const IssueDetails = ({img, text}) => (
  <IssueDetailsContainer>
    <IssueDetailsItem>{img}</IssueDetailsItem>
    <IssueDetailsItem>{text}</IssueDetailsItem>
  </IssueDetailsContainer>
)

export default IssueDetails
