import React from 'react'

import { CoverImageContainer } from "./coverImage.styles";

const CoverImage = ({url, slug}) => (
  <CoverImageContainer>
    <img src={url} alt={slug} />
  </CoverImageContainer>
)

export default CoverImage
