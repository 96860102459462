import { LanguageActionTypes } from "./language.types"
import { toggleLoader } from "../pages/pages.actions";

export const setLocale = locale => dispatch => {
  dispatch(toggleLoader(false))
  dispatch({
    type: LanguageActionTypes.SET_LOCALE,
    payload: locale
  })
  setTimeout(() => window.location.reload(true), 1000)
}
