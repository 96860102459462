import styled, { css } from 'styled-components';
import { ReactComponent as HamburgerSVG } from "../../../assets/bars-solid.svg";
import { ReactComponent as CloseSVG } from "../../../assets/times-solid.svg";
import NavItem from '../navItem/navItem.component'

export const NavbarContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  background: #fafafa;
`

export const NavbarBrandingSlot = styled.div`
  height: 100%;
  width: 25%;
  background: ${props => props.color};
  @media screen and (max-width: 1024px) {
    width: auto;
  }
`

export const NavbarBrandingItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const NavbarLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`

export const NavbarNavItem = styled(NavItem)``

const mobileopen = css`
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, .9);
    z-index: 50;
    ${NavbarLinks} {
      flex-direction: column;
      justify-content: center;
      color: #fafafa;
    }
    ${NavbarNavItem} {
      max-width: unset;
      height: auto;
      padding: 15px;
    }
  }
`

const setMobileOpen = props => props.isOpen ? mobileopen : ''

export const NavbarLinksSlot = styled.div`
  height: 100%;
  width: 75%;
  background: #fafafa;
  @media screen and (max-width: 1024px) {
    display: none;
  }

  ${setMobileOpen}
`

export const NavbarMobileIcons = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 10%;
    margin-left: auto;
    width: auto;
    background: #fafafa;
  }
`

const icon = css`
  height: 40px;
  width: 40px;
`

export const Hamburger = styled(HamburgerSVG)`
  ${icon}
`

export const Close = styled(CloseSVG)`
  ${icon}
  z-index: 52;
  color: #fafafa;
`
