import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

// import './footer.styles.scss'
import { FooterContainer, FooterParagraphContainer, FooterLogosContainer, Logo } from "./footer.styles";

import SmallLogo from '../smallLogo/smallLogo.component'

const Footer = ({header, language: {locale}}) => (
  <FooterContainer color={header.config.colors.primary}>
    <FooterParagraphContainer>
      &copy; {new Date().getFullYear()} Maciej Krawczyk & &nbsp;
      <span>
        {header.config[`title${locale}`]}
      </span>
    </FooterParagraphContainer>
    <FooterLogosContainer>
      <Link to='/dostepnosc'> Deklaracja Dostępności</Link>
      <SmallLogo target='https://www.wpia.uw.edu.pl/' >
        <Logo />
      </SmallLogo>
    </FooterLogosContainer>
  </FooterContainer>
)

const mapStateToProps = ({ header, language }) => ({header, language})

export default connect(mapStateToProps)(Footer)
