import React, { Component, Fragment } from 'react'

import { Switch, Route} from "react-router-dom"
import { connect } from "react-redux";

import './styles/styles.scss'

// Pages components
import HomePage from './pages/hompage/homepage.component'
import AdvisoryBoard from './pages/advisoryBoard/advisoryBoard.component'
import EditorialTeam from './pages/editorialTeam/editorialTeam.component'
import ForAuthors from './pages/forAuthors/forAuthors.component'
import IssueDetails from './pages/issueDetails/issueDetails.component'
import LastIssue from './pages/lastIssue/lastIssue.component'
import PastIssues from './pages/pastIssues/pastIssues.component'
import Reviewers from './pages/reviewers/reviewers.component'
import Ceeol from './pages/ceeol/ceeol.component'
import Dostepnosc from './pages/dostępność/dostepnosc.component'
import FourOhFour from './pages/404/404.component'

// Common components
import Loader from './components/common/loader/loader.component'
import Navabr from './components/common/navbar/navbar.component'
import Footer from './components/common/footer/footer.component'
import TitleComponent from './components/common/title/title.component'

// Utils
import { setHeaderTitle, setHeaderLinks } from "./redux/header/header.actions";

class App extends Component {

  componentDidMount() {
    const { setHeaderTitle, setHeaderLinks, locale } = this.props
    setHeaderLinks(locale)
    setHeaderTitle()
  }

  render() {
    return (
      <Fragment>
        {this.props.showLoader ? <Loader /> : ''}
        <TitleComponent />
        <Navabr />
        <Switch>
          <Route exact path='/'                        component={ HomePage } />
          <Route exact path='/about'                   component={ HomePage } />
          <Route exact path='/advisory-board'          component={ AdvisoryBoard } />
          <Route exact path='/editorial-team'          component={ EditorialTeam } />
          <Route exact path='/for-authors'             component={ ForAuthors } />
          <Route exact path='/issue-details/:slug'     component={ IssueDetails } />
          <Route exact path='/last-issue'              component={ LastIssue } />
          <Route exact path='/past-issues'             component={ PastIssues } />
          <Route exact path='/reviewers'               component={ Reviewers } />
          <Route exact path='/ceeol'                   component={ Ceeol } />
          <Route exact path='/dostepnosc'                   component={ Dostepnosc } />
          <Route                                       component={ FourOhFour } />
        </Switch>
        <Footer />
      </Fragment>
    )
  }
}

const mapStateToProps = ({pages: { showLoader }, language: {locale}}) => ({showLoader, locale})

export default connect(mapStateToProps, {setHeaderTitle, setHeaderLinks})(App)
