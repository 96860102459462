import React from 'react'
import { connect } from "react-redux";

import { ButtonPrimaryContainer, ButtonPrimaryFront, ButtonPrimaryBack } from "./buttonPrimary.styles";

const ButtonPrimary = ({url, front, back, smallright, config: {colors}}) => (
  <ButtonPrimaryContainer to={url} smallright={smallright} color={colors.primary} >
    <ButtonPrimaryFront>
      {front}
    </ButtonPrimaryFront>
    <ButtonPrimaryBack>
      {back}
    </ButtonPrimaryBack>
  </ButtonPrimaryContainer>
)

const mapStateToProps = ({header: { config }}, otherProps) => ({config, otherProps})

export default connect(mapStateToProps)(ButtonPrimary)
