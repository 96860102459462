import React from 'react'

import { NavItemContainer, NavItemText, NavItemLinkWrapper } from "./navItem.styles";

const NavItem = ({url, name}) => (
  <NavItemContainer>
    <NavItemLinkWrapper to={`/${url}`} activeClassName='is-active'>
      <NavItemText>{name}</NavItemText>
    </NavItemLinkWrapper>
  </NavItemContainer>
)

export default NavItem
