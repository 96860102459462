import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import IssueDetailsGrid from '../../components/grid/issueDetailsGrid/issueDetails.component'
import HtmlContent from '../../components/common/htmlContent/htmlContent.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import CoverImage from '../../components/common/coverImage/coverImage.component'
import TitleComponent from '../../components/common/title/title.component'

import { setIssueDetails, toggleLoader } from '../../redux/pages/pages.actions'

class IssueDetails extends Component {

  componentDidMount() {
    const { setIssueDetails, toggleLoader, match: { params: { slug } }, locale } = this.props
    toggleLoader()
    setIssueDetails(`${slug}-${locale}`)
  }

  render() {
    const { issueDetails: { title, content, slug, img : {url} }, config, locale } = this.props
    const mainContent =(
      <IssueDetailsGrid
        img={<CoverImage url={url} slug={slug}/>}
        text={<HtmlContent html={content} />}
      />
    )
    const header = (<MainHeader text={title} />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | ${title}`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ pages: { issueDetails }, header: { config }, language: { locale }}) => ({ issueDetails, config, locale })

export default connect(mapStateToProps, { setIssueDetails, toggleLoader })(IssueDetails)
