import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import IssueDetailsGrid from '../../components/grid/issueDetailsGrid/issueDetails.component'
import HtmlContent from '../../components/common/htmlContent/htmlContent.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import CoverImage from '../../components/common/coverImage/coverImage.component'
import TitleComponent from '../../components/common/title/title.component'

import { setLastIssue, toggleLoader } from '../../redux/pages/pages.actions'

class LastIssue extends Component {

  componentDidMount() {
    const { setLastIssue, toggleLoader, locale } = this.props
    toggleLoader()
    setLastIssue(locale)
  }

  render() {
    const { lastPost: { title, content, slug, img : {url} }, config, locale } = this.props
    const mainContent =(
      <IssueDetailsGrid
        img={<CoverImage url={url} slug={slug}/>}
        text={<HtmlContent html={content} />}
      />
    )

    const header = (<MainHeader text={title} />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | ${title}`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ pages: { lastPost }, language: { locale }, header: { config }}) => ({ lastPost, locale, config })

export default connect(mapStateToProps, { setLastIssue, toggleLoader })(LastIssue)
