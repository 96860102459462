import styled from 'styled-components'
import { Link } from "react-router-dom"
import { subheader } from "../../../styles/common.styles";

export const BrandingContainer = styled.div`
  margin: 10px;
`

export const LinkContainer = styled(Link)`
  text-decoration: none;
  `

export const BrandingTextContainer = styled.h1`
  ${subheader}
  margin: 0;
  color: #fafafa;
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 400;
`

export const BrandingWordContainer = styled.span`
  display: inline-block;
  font-size: 100%;
  &:first-letter{
    font-size: 125%;
  }
`
