import React from 'react'

import { LandingGridContainer, LandingGridRowMain, LandingGridRowMainLeft, LandingGridRowMainRight, LandingGridRowSub, LandingGridRowSubLeft, LandingGridRowSubRight, LandingGridRowTitle } from "./landingGrid.styles";

const LandingGrid = ({Title,  SlotA, SlotB, SlotC, SlotD, config: { colors, coverimage }}) => (
  <LandingGridContainer>
    <LandingGridRowTitle>{Title}</LandingGridRowTitle>
    <LandingGridRowSub>
      <LandingGridRowSubLeft color={colors.secondary}>{SlotA}</LandingGridRowSubLeft>
      <LandingGridRowSubRight color={colors.primary}>{SlotB}</LandingGridRowSubRight>
    </LandingGridRowSub>
    <LandingGridRowMain img={coverimage}>
      <LandingGridRowMainLeft>{SlotC}</LandingGridRowMainLeft>
      <LandingGridRowMainRight color={colors.primaryopacity}>{SlotD}</LandingGridRowMainRight>
    </LandingGridRowMain>
  </LandingGridContainer>
)

export default LandingGrid
