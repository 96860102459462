import { HeaderActionTypes } from "./header.types"
import { comparePagesOrder } from "../utils";

export const setHeaderTitle =  () => dispatch => {
  fetch('/api/index.php/wp-json', { headers: { "accepts":"application/json" } })
    .then(res => res.json())
    .then(({description}) =>
      dispatch({
        type: HeaderActionTypes.SET_HEADER_TITLE,
        payload: JSON.parse(description.replace(/&quot;/g, '"'))
      })
    )
}

export const setHeaderLinks = locale => dispatch => {
  fetch('/api/index.php/wp-json/wp/v2/pages?per_page=30', { headers: { "accepts":"application/json" } })
    .then(res => res.json())
    .then(pages =>
      dispatch({
        type: HeaderActionTypes.SET_HEADER_LINKS,
        payload: pages
                   .filter(item => item.slug.slice(item.slug.length -2) === locale ? item : null)
                   .sort(comparePagesOrder)
                   .map(({id, slug, title}) => ({id, url: slug.slice(0, -3), name: title.rendered}))
      })
    )
}
