import React from 'react'
import { connect } from 'react-redux'

import { setLocale } from '../../../redux/language/language.action'

import { Polish, English } from "./languagePicker.styles";

const LanguagePicker = ({ locale, setLocale }) =>
  locale === 'pl' ? <English className='languagepicker__icon' onClick={() => setLocale('en')} /> : <Polish className='languagepicker__icon' onClick={() => setLocale('pl')} />

const mapStateToProps = ({language : { locale }}) => ({ locale })

export default connect(mapStateToProps, { setLocale })(LanguagePicker)
