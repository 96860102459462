import styled from 'styled-components'
import { ReactComponent as LogoSVG } from "../../../assets/logo.svg";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: ${props => props.color};
  color: #fafafa;
  @media screen and (max-width: 760px) {
    position: static;
  }
`

export const FooterParagraphContainer = styled.div`
  font-size: 14px;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  margin: 0 20px;
  font-weight: 700;
  span {
    text-transform: uppercase;
  }
  @media screen and (max-width: 760px) {
    font-size: 10px;
  }
`

export const FooterLogosContainer = styled.div`
  height: 100%;
  width: auto;
  color: #fafafa;
  display: flex;
  align-items: center;
  a {
    color: #fafafa;
    &:visited {
      color: #fafafa;
    }
    font-size: 14px;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    @media screen and (max-width: 760px) {
      font-size: 10px;
    }
  }
`
export const Logo = styled(LogoSVG)`
  height: 30px;
  margin: 5px;
  color: #fafafa;
`
