import React from 'react'
import Helmet from 'react-helmet'

const TitleComponent = ({ title }) => (
  <Helmet>
    <title>{title ? title : 'U schyłku starożytności'}</title>
  </Helmet>
)

export default TitleComponent
