import styled, { css } from 'styled-components'
import { Link } from "react-router-dom";

const smallright = css`
  max-width: 250px;
  margin-left: auto;
`
const isSmallright = props => props.smallright ? smallright : ''

export const ButtonPrimaryFront = styled.span`
  display: block;
`
export const ButtonPrimaryBack = styled.span`
  display: none;
  transform: scale(-1, 1);
`
export const ButtonPrimaryContainer = styled(Link)`
  background: ${props => props.color};
  height: 100%;
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  color: #fafafa !important;
  text-decoration: none;
  text-transform: capitalize;
  &:hover {
    color: inherit;
    transform: scale(1.1) rotateY(-180deg);
    ${ButtonPrimaryFront} {
      display: none;
    }
    ${ButtonPrimaryBack} {
      display: block;
    }
  }
  ${isSmallright}
`
