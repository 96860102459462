import styled, { css } from 'styled-components';
import { ReactComponent as PolishSVG } from "../../../assets/poland.svg";
import { ReactComponent as EnglishSVG } from "../../../assets/united-kingdom.svg";

const icon = css`
  height: 30px;
  width: auto;
  margin: 0 20px;
  cursor: pointer;
`

export const Polish = styled(PolishSVG)`${icon}`

export const English = styled(EnglishSVG)`${icon}`
