import React from 'react'

import { RightparagraphContainer } from "./rightParagraph.styles";

const RightParagraph = ({paragraph}) => (
  <RightparagraphContainer>
    {paragraph}
  </RightparagraphContainer>
)

export default RightParagraph
