import React, { Component, Fragment } from 'react'
import { connect } from "react-redux"

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import SubHeader from '../../components/common/subHeader/subHeader.component'
import RightParagraph from '../../components/common/rightParagraph/rightParagraph.component'
import ButtonPrimary from '../../components/common/buttonPrimary/buttonPrimary.component'
import TitleComponent from '../../components/common/title/title.component';

import { toggleLoader } from '../../redux/pages/pages.actions'

class FourOhFour extends Component {

  componentDidMount() {
    const { toggleLoader } = this.props
    toggleLoader(true)
  }


  render() {
    const { locale, config } = this.props
    const button = (<ButtonPrimary url='/editorial-team' front={locale === 'pl' ? 'Kontakt' : 'Contact'} back={locale === 'pl' ? 'Redakcja' : 'Editorial team'} smallright='true' />)
    const mainContent = (
      <Fragment>
        <SubHeader text={locale === 'pl' ? 'Strony nie znaleziono.' : 'Page not found.'} />
        <RightParagraph paragraph={locale === 'pl' ? 'Jeżeli uważasz, że to błąd skontaktuj się z nami.' : 'If you believe this is an error, please contact us.'} />
        <RightParagraph paragraph={button} />
      </Fragment>
    )
    const header = (<MainHeader text={'404'} />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | 404`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({language: { locale }, header: { config }}) => ({ locale, config })

export default connect(mapStateToProps,{ toggleLoader })(FourOhFour)
