import React from 'react'

import RightParagraph from '../rightParagraph/rightParagraph.component'
import ArrowLeft from '../arrowLeft/arrowLeft.component'

import { SlideParagrapfContainer, SlideParagrapfArrow, SlideParagrapfTexts } from "./slideParagraph.styles";

const SlideParagraph = ({content}) => (
  <SlideParagrapfContainer>
    <SlideParagrapfArrow>
      <ArrowLeft />
    </SlideParagrapfArrow>
    <SlideParagrapfTexts>
      {content.map((paragraph, i) => (<RightParagraph key={i} paragraph={paragraph} />))}
    </SlideParagrapfTexts>
  </SlideParagrapfContainer>
)

export default SlideParagraph
