import styled from 'styled-components';

export const IssueItemGridContainer = styled.div`
  margin: 20px 0 60px;
  min-height: 300px;
  max-width: 1100px;
  width: 100%;
  display: grid;
  grid-template-columns: 20% calc(80% - 10px);
  grid-template-rows: 50px 180px 50px;
  grid-template-areas:
  "img title"
  "img text"
  "img button";
  gap: 10px;
  @media screen and (max-width: 760px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
    "img"
    "button"
    "title"
    "text";
    gap: 10px;
  }
`

export const IssueItemGridImg = styled.div`
  grid-area: img;
`

export const IssueItemGridTitle = styled.div`
  grid-area: title;
`

export const IssueItemGridButton = styled.div`
  grid-area: button;
  margin-left: auto;
  @media screen and (max-width: 760px) {
    margin: 0 auto;
  }
`

export const IssueItemGridText = styled.div`
  grid-area: text;
  text-align: right;
`
