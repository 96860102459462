import { HeaderActionTypes } from './header.types'

const INITIAL_STATE = {
  config: {
    titlepl: '',
    titleen: '',
    subtitlepl: '',
    subtitleen: '',
    coverimage: '',
    colors:{
      primary: '',
      secondary: '',
      primaryopacity: ''
    }
  },
  hompageUrl: '/',
  pages: []
}

const headerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HeaderActionTypes.SET_HEADER_TITLE:
      return {
        ...state,
        config: action.payload
      }
    case HeaderActionTypes.SET_HEADER_LINKS:
      return {
        ...state,
        pages: action.payload
      }
    default:
      return state
  }
}

export default headerReducer
