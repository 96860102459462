import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";

import SubpageGrid from '../../components/grid/subpageGrid/subpageGrid.component'
import HtmlContent from '../../components/common/htmlContent/htmlContent.component'
import MainHeader from '../../components/common/mainHeader/mainHeader.component'
import TitleComponent from '../../components/common/title/title.component';

import { toggleLoader } from '../../redux/pages/pages.actions'

class Dostepnosc extends Component {

  componentDidMount() {
    const { toggleLoader } = this.props
    toggleLoader(true)
  }

  render() {
    const { config, locale } = this.props
    const mainContent = (<HtmlContent html={
      `
      <h1 id="a11y-deklaracja">Deklaracja dostępności</h1>
      <p id="a11y-wstep">
        <span id="a11y-podmiot">Uniwersytet Warszawski</span> zobowiązuje się zapewnić dostępność swojej strony internetowej
        zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych
        podmiotów publicznych. Oświadczenie w sprawie dostępnści ma zastosowanie do strony internetowej <a id="a11y-url"
          href="http://uss.wpia.uw.edu.pl"><strong>uss.wpia.uw.edu.pl</strong></a>
      </p>
      <ul>
        <li>Data publikacji strony internetowej: <span id="a11y-data-publikacja"><strong>2020-04-02</strong></span>.</li>
        <li>Data ostatniej istotnej aktualizacji: <span id="a11y-data-aktualizacja"><strong>2020-04-02 </strong></span>.</li>
      </ul>

      <h2>Status zgodności z ustawą o dostępności cyfrowej</h2>

      <div id="a11y-status">
        <p>Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
          internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej.
        </p>

      </div>
      <ul>
        <li>Tytuły podstron</li>
        <ul>
          <li>Istotna informacja o nazwie strony umieszczona jest na końcu tytułu.</li>
        </ul>
        <li>Kotwice (odnośniki w obrębie strony)</li>
        <ul>
          <li>Serwis nie posiada odnośników umożliwiających bezpośrednie przejście do treści stron.</li>
        </ul>
        <li>Linki</li>
        <ul>
          <li>Serwis posiada odnośniki kontekstowe, których treść nie informuje jednoznacznie o celu.</li>
          <li>Serwis posiada odnośniki do pobrania dokumentów bez informacji o ich formacie.</li>
          <li>Serwis posiada odnośniki, których treścią jest adres URL.</li>
        </ul>
        <li>Znacznik języka strony lub treści tekstowej.</li>
        <ul>
          <li>Serwis zawiera znacznik języka niezgodnego z językiem treści.</li>
        </ul>
        <li>Nagłówki</li>
        <ul>
          <li>W serwisie występują problemy z właściwą definicją struktury nagłówków.</li>
        </ul>
        <li>Alternatywne opisy dla grafiki</li>
        <ul>
          <li>Serwis posiada ilustracje bez napisów alternatywnych lub są one niewystarczające.</li>
        </ul>
        <li>Elementy klikalne</li>
        <ul>
          <li>W serwisie występują elementy klikalne niedostępne z poziomu klawiatury.</li>
        </ul>
        <li>Obszary strony, tzw. landmarki</li>
        <ul>
          <li>Serwis nie posiada zdefiniowanych obszarów tzw. landmarków.</li>
        </ul>
        <li>Tabele</li>
        <ul>
          <li>Tabele nie posiadają zdefiniowanych komórek nagłówkowych.</li>
        </ul>
        <li>Dostępność wszystkich elementów na stronie</li>
        <ul>
          <li>W serwisie występują elementy, które nie są dostępne za pomocą klawiatury.</li>
        </ul>
        <li>Wyszukiwanie w serwisie</li>
        <ul>
          <li>Serwis nie posiada mechanizmu wyszukiwania.</li>
        </ul>
        <li>Dostępność materiałów zamieszczonych na stronie</li>
        <ul>
          <li>Serwis może posiadać odnośniki do dokumentów, które nie są dostępne.</li>
        </ul>
        <li>Mapa serwisu, zrozumiałość struktury serwisu, łatwość odnajdywania informacji</li>
        <ul>
          <li>Serwis nie posiada mapy stron.</li>
          <li>Serwis nie zawiera kontaktu do administratora strony.</li>
        </ul>
        <li>Kontrasty i kolorystyka strony</li>
        <ul>
          <li>Serwis posiada teksty umieszczane na ilustracjach, które mogą nie spełniać wymogów kontrastu.</li>
        </ul>
        <li>Dostępność elementów klikalnych</li>
        <ul>
          <li>Strona zawiera elementy klikalne, które nie są obsługiwane przy użyciu klawiatury.</li>
          <li>W serwisie występują elementy klikalne bez etykiet lub jednoznacznych tekstów linków.</li>
        </ul>
        <li>Elementy dynamiczne strony</li>
        <ul>
          <li>Serwis posiada elementy rozwijalne, które nie są dostępne przy użyciu klawiatury.</li>
        </ul>
        <li>Wyróżnienie linków i innych istotnych elementów strony</li>
        <ul>
          <li>Serwis posiada odnośniki wyróżnione tylko kolorem.</li>
        </ul>
        <li>Walidacja HTML/CSS</li>
        <ul>
          <li>W serwisie występują problemy z walidacją HTML.</li>
        </ul>
        <li>Dostępność interfejsu użytkownika</li>
        <ul>
          <li>Strona zawiera elementy bez atrybutów odczytywanych przez technologie wspomagające.</li>
        </ul>
        <li>Inne zauważone problemy dostępności.</li>
        <ul>
          <li>Treść na stronie głównej wyświetlana jest jako tekst na ilustracji tylko po najechaniu kursorem. Treść jest
            niedostępna z klawiatury oraz nie spełnia minimalnych wymogów kontrastu.</li>
        </ul>
      </ul>
      <p>
        Oświadczenie sporządzono dnia: <span id="a11y-data-sporzadzenie"><strong>2020-11-09</strong></span>. Deklarację
        sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny: <span id=" a11y-audytor"><strong><a
              href="https://bon.uw.edu.pl">Biuro ds. Osób Niepełnosprawnych UW</a></strong></span>.
      </p>

      <h2>Skróty klawiaturowe</h2>

      <p>Na stronie <strong><a href=http://uss.wpia.uw.edu.pl>uss.wpia.uw.edu.pl</a></strong> nie zastosowano żadnych
        specjalnych skrótów klawiaturowych.</p>

      <h2 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h2>

      <p>Osobą kontaktową w sprawach związanych z dostępnością tej strony internetowej jest <span
          id="a11y-osoba"><strong>Mateusz Klukowski</strong></span>.
        W przypadku problemów z dostępnością strony internetowej prosimy o kontakt: e-mail: <span
          id="a11y-email"><strong>webmaster@wpia.uw.edu.pl</strong></span>, telefon <span id="a11y-telefon"><strong>22 55 24
            355</strong></span>.
        Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać żądania zapewnienia
        dostępności.</p>

      <h2 id="a11y-procedura">Procedura wnioskowo-skargowa</h2>

      <p>Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub
        jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na
        przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp.</p>

      <p>Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną
        chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego
        sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji.</p>

      <p>Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z
        żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego
        żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia
        wystąpienia z żądaniem.</p>

      <p>Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób
        dostępu do informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub
        alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności
        cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej. Po
        wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.</p>

      <h2 id="a11y-architektura">Dostępność architektoniczna</h2>
      <p>Pełen wykaz budynków należących do UW znajduje się na <strong><a
            href="http://uw.edu.pl/deklaracja-dostepnosci">stronie deklaracji dostępności UW</a></strong>.</p>
      <p>Opis w przygotowaniu
      `
    } />)
    const header = (<MainHeader text="Dostępność" />)
    return (
      <Fragment>
        <TitleComponent title={`${config[`title${locale}`]} | Dostępność`} />
        <SubpageGrid header={header} content={mainContent} config={config} />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ pages: { page }, language: { locale }, header: {config}}) => ({ page, locale, config })

export default connect(mapStateToProps, { toggleLoader })(Dostepnosc)
