import React from 'react'

import { SmalllogoContainer } from "./smallLogo.styles";

const SmallLogo = ({target, children}) => (
  <SmalllogoContainer href={target} target='_blank' rel="noopener noreferrer">
    {children}
  </SmalllogoContainer>
)

export default SmallLogo
