const stripTags = html => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}

export const getParagraphsText = html => {
  const htmlArr = html.split('<p>')
  const arrToReturn = htmlArr.map(item => item.split("\u21b5").join('')).map(item => stripTags(item)).filter(item => item.length > 1)
  return arrToReturn
}
