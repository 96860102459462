import styled from 'styled-components';
import { subheader } from "../../../styles/common.styles";

export const SubheaderContainer = styled.div`
  ${subheader}
  text-align: right;
  margin: 10px;
  font-weight: 400;
  text-transform: capitalize;
`
