import React from 'react'

import { IssueItemGridContainer, IssueItemGridImg, IssueItemGridTitle, IssueItemGridButton, IssueItemGridText } from "./issueItemGrid.styles";

const IssueItemGrid = ({img, button, title, text}) => (
  <IssueItemGridContainer>
    <IssueItemGridImg>{img}</IssueItemGridImg>
    <IssueItemGridTitle>{title}</IssueItemGridTitle>
    <IssueItemGridButton>{button}</IssueItemGridButton>
    <IssueItemGridText>{text}</IssueItemGridText>
  </IssueItemGridContainer>
)

export default IssueItemGrid
