import React from 'react'

import { SubPageGridContainer, SubPageGridContent, SubPageGridHeader, SubPageGridHr } from "./subpageGrid.styles";

const SubpageGrid = ({header, content, config}) => (
  <SubPageGridContainer color={config.colors.primary} >
    <SubPageGridHeader>{header}</SubPageGridHeader>
    <SubPageGridHr color={config.colors.primary} />
    <SubPageGridContent>{content}</SubPageGridContent>
  </SubPageGridContainer>
)

export default SubpageGrid
