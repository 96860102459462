import React from 'react'

import { MainHeaderContainer, MainHeaderWord } from "./mainHeader.styles";

const MainHeader = ({text}) => (
  <MainHeaderContainer>
    {text.split(' ').map((word, i) => (<MainHeaderWord key={i}>{word}&nbsp;</MainHeaderWord>))}
  </MainHeaderContainer>
)

export default MainHeader
