export const PagesActionTypes = {
  SET_HOMEPAGE_CONTENT: 'SET_HOMEPAGE_CONTENT',
  SET_SUBPAGE_CONTENT:  'SET_SUBPAGE_CONTENT',
  SET_POSTS_LIST:       'SET_POSTS_LIST',
  SET_LAST_ISSUE:       'SET_LAST_ISSUE',
  SET_ISSUE_DETAILS:    'SET_ISSUE_DETAILS',
  TOGGLE_LOADER:        'TOGGLE_LOADER'
}

export default PagesActionTypes
